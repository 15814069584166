<template>
  <div class="page-box">
    <div class="meeting-box">
      <a v-for="(item,index) in listTop" :key="index" class="modle-box" :href="item.url" target="_blank">
        <div class="meeting-title">{{item.title}}</div>
        <div class="meeting-time">
          <div>{{item.date}}</div>
          <div style="margin-top:5px">{{item.time}}</div>
        </div>
        <img style="width:190px;height:60px" src="../../assets/images/linkBt.png"/>
      </a>
      <!-- <a v-for="(item,index) in dataList" :key="index" :href="item.url" target="_blank" class="meeting-img1">
           <img style="width:100%;height:100%" :src="item.img"/>
      </a> -->
      <!-- <div v-for="(item,index) in dataList1" :key="index" class="meeting-img2">
           <img style="width:100%;height:100%" :src="item.img"/>
      </div> -->
      <a v-for="(item,index) in listBottom" :key="index" class="modle-box-bottom" :href="item.url" target="_blank">
        <div class="meeting-title">{{item.title}}</div>
        <div class="meeting-title">{{item.dsc}}</div>
        <div class="meeting-time">
          <div>{{item.date}}</div>
          <div style="margin-top:5px">{{item.time}}</div>
        </div>
        <img style="width:190px;height:60px" src="../../assets/images/linkBt.png"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["language"],
  data() {
    return {
      dataList: [
        {
          img: require("../../assets/images/special1.png"),
          url: "https://live-one.tscloud-tech.com/live/watch?id=od7jeyeo",
        },
        {
          img: require("../../assets/images/special2.png"),
          url: "https://live-one.tscloud-tech.com/live/watch?id=o6d8z63l",
        },
        {
          img: require("../../assets/images/special3.png"),
          url: "https://live-one.tscloud-tech.com/live/watch?id=o52rdxvo",
        },
        {
          img: require("../../assets/images/special4.png"),
          url: "https://live-one.tscloud-tech.com/live/watch?id=o024zjvm",
        },
      ],
      dataList1: [
        {
          img: require("../../assets/images/special5.png"),
          url: "https://live-one.tscloud-tech.com/live/watch?id=mped8r2o",
        },
        {
          img: require("../../assets/images/special6.png"),
          url: "https://live-one.tscloud-tech.com/live/watch?id=o1dge7xo",
        },
        {
          img: require("../../assets/images/special7.png"),
          url: "https://live-one.tscloud-tech.com/live/watch?id=mz1x6ppl",
        },
      ],
      listTop: [
        {
          title:'线上专场一',
          date: '8月5日',
          time: '18:00-20:20',
          url:'https://live-one.tscloud-tech.com/live/watch?id=od7jeyeo'
        },
        {
          title:'线上专场二',
          date: '8月5日',
          time: '18:00-20:20',
          url:'https://live-one.tscloud-tech.com/live/watch?id=o6d8z63l'
        },
        {
          title:'线上专场三',
          date: '8月6日',
          time: '18:00-20:20',
          url:'https://live-one.tscloud-tech.com/live/watch?id=o52rdxvo'
        },
        {
          title:'线上专场四',
          date: '8月5日',
          time: '18:00-20:20',
          url:'https://live-one.tscloud-tech.com/live/watch?id=o024zjvm'
        },
      ],
      listBottom: [
        {
          title:'CNF&CBD2021',
          date: '10月23日',
          dsc:'开幕式',
          time: '08:30-12:30',
          url: "https://live-one.tscloud-tech.com/live/watch?id=mped8r2o",
        },
        {
          title:'CNF&CBD2021',
          date: '10月23日',
          dsc:'认知与脑血管论坛',
          time: '14:00-17:30',
          url: "https://live-one.tscloud-tech.com/live/watch?id=o1dge7xo",
        },
        {
          title:'CNF&CBD2021',
          date: '10月23日',
          dsc:'认知与其他脑疾病论坛',
          time: '14:00-17:30',
          url: "https://live-one.tscloud-tech.com/live/watch?id=mz1x6ppl",
        },
      ],
    };
  },
};
</script>

<style lang='less'>
.page-box {
  width: 100%;
  min-width: 1200px;
  aspect-ratio: 1903/1372;
  height: 100%;
  background-image: url("../../assets/images/meettingBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 15.7%;
    box-sizing: border-box;
  .meeting-box {
    margin-top: 110px;
      width: 63.02%;
      min-width: 1200px;
      height: 37.02%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .meeting-img1{
      width: 25%;
      aspect-ratio: 295/255;
  }
  .meeting-img2{
      width: 33.3%;
      aspect-ratio: 402/249;
  }
  .modle-box {
    width: 22.08%;
    min-width: 190px;
    height: 23vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 0px 10px 9px #19356e;
    border: 3px solid #2a3b91;
    background-color: #151955;
    border-radius: 10px;
  }
  .meeting-title {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .meeting-time {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 2px;
  }
    .modle-box-bottom {
    width: 30%;
    min-width: 210px;
    height: 24vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 0px 10px 9px #19356e;
    border: 3px solid #2a3b91;
    background-color: #151955;
    border-radius: 10px;
    margin-top: 50px;
  }
}
</style>